@media screen and (min-width: 1025px) {
  .page-wrapper.toggled .page-content {
    padding-left: 268px;
  }
}

@media screen and (max-width: 1025px) {
  .page-wrapper.toggled .page-content {
    padding-left: 268px;
  }
}

@media screen and (max-width: 1025px) {
  .sidebar-collapsed .top-header {
    left: 80px;
  }
  .sidebar-opened .top-header {
    left: 276px !important;
  }
}

.page-wrapper.landrick-theme.sidebar-opened {
  left: 250px;
}

.page-wrapper.toggled .top-header {
  left: 268px;
}

.page-wrapper.sidebar-collapsed .top-header {
  left: 80px;
}

.page-wrapper.sidebar-collapsed .page-content {
  padding-left: 80px;
}

.sidebar-collapsed .ant-menu-title-content {
  opacity: 0 !important;
  display: none !important;
}

.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-menu li.active {
  background-color: #f2f8f8 !important;
  border-radius: 5px;
  color: #56aaaa !important;
}

.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-menu li.active > span,
.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-menu li.active span:nth-child(2),
.page-wrapper.landrick-theme .sidebar-wrapper .sidebar-menu li.active i {
  color: #56aaaa !important;
}

.page-wrapper.landrick-theme li.active > span > a {
  color: black !important;
}

.page-wrapper.landrick-theme li:hover > a {
  color: none !important;
}

.subMenuTaxAccountant li {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
}

.subMenuTaxAccountant {
  height: 100%;
}

.minimizedSideBarFirst a {
  scale: 1.2;
}

.minimizedSideBarFirst li:not(.active):hover img,
.minimizedSideBarFirst li:not(.active):hover svg {
  filter: none;
}

.sidebar-collapsed .minimizedSideBarFirst li:not(.active):hover li:not(.active) svg,
.sidebar-collapsed .minimizedSideBarFirst li:not(.active):hover li:not(.active) img {
  filter: invert(-1) !important;
}

.sidebar-collapsed .minimizedSideBarFirst li:not(.active) svg,
.sidebar-collapsed .minimizedSideBarFirst li:not(.active) img {
  filter: invert(1) !important; /* Default filter */
}

.page-wrapper.landrick-theme li.active img {
  filter: brightness(1) invert(0.6) sepia(0.6) hue-rotate(132deg) saturate(200%) !important;
}

.minimizedSideBarFirst li svg {
  scale: 1.3;
}

ul.minimizedSideBarFirst {
  margin: 5px 0 0 0 !important;
}

.logo-icon svg {
  color: white;
}

.sidebar-collapsed .sidebar-brand {
  width: 80px !important;
}

.sidebar-collapsed .ant-menu-item-group-list li {
  padding: initial;
  width: initial;
  margin: initial;
}

.sidebar-opened li img {
  margin: 0 10px 0 0;
}

.logo-icon {
  transition: all 0.3s ease !important;
}

.sidebar-collapsed .ant-menu-submenu-arrow {
  display: none !important;
}

.sidebar-wrapper {
  transition: all 0.3s ease !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.sidebar-wrapper .ant-menu-item-group-title {
  display: none;
}

.sidebar-wrapper .ant-menu-light .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu-item-group .ant-menu-item-group-list li.ant-menu-item-selected:not(.active) {
  background-color: white;
}

.ant-menu-item-group .ant-menu-item-group-list li:not(.active):hover {
  background-color: rgba(0, 0, 0, 0.06) !important;
}

.ant-menu-light:not(.ant-menu-horizontal) li.active .ant-menu-submenu-title:hover {
  background-color: transparent !important;
}

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
  width: 275px;
}

.sidebar-collapsed .sidebar-wrapper {
  width: 80px !important;
}

.sidebar-collapsed .ant-menu-item-group-list li {
  padding: 0 !important;
  padding-left: 24px !important;
}

.sidebar-wrapper .ant-menu-submenu.ant-menu-submenu-hidden.ant-menu-submenu-popup.ant-menu .ant-menu-light li.menuitem {
  padding: 0 !important;
  padding-left: 24px !important;
}

#sidebar li.ant-menu-submenu.active .ant-menu-item-group {
  margin-top: 1px;
}

#sidebar .sidebar-collapsed li {
  width: 80% !important;
  margin: auto !important;
}

.sidebar-wrapper .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-inline: calc(45% - 8px - 4px);
}
.sidebar-wrapper .ant-menu-inline-collapsed > .ant-menu-item {
  padding-inline: calc(45% - 8px - 4px);
}
.sidebar-wrapper .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 50px;
  line-height: 50px;
}

.sidebar-wrapper .ant-menu-vertical > .ant-menu-item {
  height: 50px;
  line-height: 50px;
}
.sidebar-wrapper .ant-menu-inline .ant-menu-item {
  height: 50px;
  line-height: 50px;
}
.sidebar-wrapper .ant-menu-submenu > .ant-menu-submenu-title {
  height: 50px;
  line-height: 50px;
}
.sidebar-wrapper .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 50px;
  line-height: 50px;
}
.sidebar-wrapper .ant-menu-inline .ant-menu-item,
.sidebar-wrapper .ant-menu-inline .ant-menu-item:hover {
  margin-inline: 0px;
  width: auto;
}
.sidebar-wrapper .ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title,
.sidebar-wrapper .ant-menu-inline .ant-menu-submenu .ant-menu-submenu-title:hover {
  margin-inline: 0px;
  width: auto;
}
.sidebar-wrapper .ant-menu-vertical .ant-menu-item {
  margin-inline: 0px;
  width: auto;
}
.sidebar-wrapper .ant-menu-vertical .ant-menu-submenu-title {
  margin-inline: 0px;
  width: auto;
}
