.amplify-tabs__list {
  display: none;
}
[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  border-radius: 6px;
}
[data-amplify-authenticator] [data-amplify-form] {
  padding: 16px 32px;
}

.amplify-button--primary {
  margin: 10px 0 0;
  background-color: #56aaaa;
  border: 1px solid #56aaaa;
  border-radius: 6px;
}

.amplify-button--disabled {
  color: #304050;
}
