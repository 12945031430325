/* globle css */
/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  border-radius: 50px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dropdown__toggle {
  border: none;
}

.docFile {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.expense_input .settlement {
  max-height: 500px;
  overflow-y: auto;
}

.date__icon {
  position: absolute;
  top: 14%;
  right: 11px;
}

.login__icon {
  position: absolute;
  top: 20%;
  left: 11px;
}

.login_card_body {
  padding: 2.5rem 1.5rem !important;
}

.login_card {
  box-shadow: 0 0 3px rgb(60 72 88 / 50%) !important;
}

.login_bg {
  background-image: url('../../assets/images/bg-login.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.ant-table-thead > tr > th {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold !important;
  text-align: left;
  background: #fafafa;
  background: #f2f8f8 !important;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
  border-right: 1px solid #0000001a !important;
}

.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 10px !important;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-table {
  border-radius: 10px !important;
}

.ant-spin-dot-item {
  background-color: #56aaaa;
}

.property_edit {
  padding: 8px 12px;
}

.ant-modal-content {
  border-radius: 10px !important;
}

.ant-modal-header {
  border-radius: 10px 10px 0 0 !important;
}

.ant-btn {
  border-radius: 6px !important;
}

/* .ant-btn-primary {
  color: #fff;
  background: #56aaaa;
}

.ant-btn-primary:hover {
  background: #56aaaade !important;
}

.ant-btn-primary.ant-btn-dangerous {
  background: #ff4d4f;
}

.ant-btn-primary.ant-btn-dangerous:hover {
  background: #ff7875 !important;
} */

.ant-modal-footer {
  padding: 15px 16px !important;
}

.ant-modal-title {
  font-size: 20px !important;
}

/* .anticon svg {
  width: 14px !important;
  height: 14px !important;
} */

.edit_valuation {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.cancel_button:hover,
.cancel_button:focus {
  background: #fff !important;
  color: #000 !important;
}

.cancel_button {
  background: #fff !important;
  color: #000 !important;
  border-color: #cdcaca !important;
}

.button_primary {
  color: #fff !important;
}

.spinner-border {
  --bs-spinner-width: 20px !important;
  --bs-spinner-height: 20px !important;
}

.overflow__scroll {
  max-height: 330px;
  overflow-y: auto;
}

.login__eye {
  position: absolute;
  top: 13px;
  right: 15px;
  cursor: pointer;
}

/* dashboard start */
.dashboard_wrapper .card {
  border-radius: 10px !important;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
  border: none !important;
  height: 250px;
  overflow: auto;
}

.dashboard_wrapper .card p {
  word-break: break-all;
  margin: 0 5px 0 0;
}

/* .dashboard_wrapper .card::-webkit-scrollbar {
    height: 2px;
} */

.dashboard_wrapper .card ul {
  border-radius: 10px !important;
}

.dashboard_wrapper .card ul li h5 {
  color: #211e1e;
  font-size: 16px;
  font-weight: 600px;
}

.dashboard_wrapper .card ul li p {
  color: #6e6b7b;
  font-size: 14px;
  font-weight: 400px;
}

.depreciation_dashboard a {
  padding: 10px 20px;
  border: 1px solid #a7a9ac70;
  color: #211e1e;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
}

.depreciation_dashboard p {
  padding: 10px 20px;
  border: 1px solid #a7a9ac70;
  color: #211e1e;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  cursor: pointer;
}

.dashboard_unique_email {
  margin: 12px 0 12px 0;
}

.list-group-item .unique_email {
  color: #211e1e !important;
}

/* dashboard end */

.btn_goBack {
  border: 1px solid #a7a9ac70;
  margin-right: 10px;
  /* padding: 5px 20px;
  color: #6e6b7b;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  cursor: pointer; */
  background: transparent;
}
.btn_goBack:hover {
  color: #030b16 !important;
  background: #fff;
}

.btn_logout {
  padding: 0 18px !important;
  font-size: 14px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.dollar_sign {
  position: absolute;
  top: 31%;
  left: 10px;
}

.react-tel-input .form-control {
  padding: 21px 0 21px 47px;
  width: 260px !important;
}
