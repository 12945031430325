.customAntInput {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.customAntSelect {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  /* background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 strokeLinecap=%27round%27 strokeLinejoin=%27round%27 strokeWidth=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e); */
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

.ant-input:hover {
  /* border: 1px solid #041e421a !important; */
  border-right-width: 1px;
}

.custom-search-parent .ant-input:hover {
  border: none !important;
}
.custom-search-parent .ant-input-outlined:focus-within {
  box-shadow: none !important;
}

.custom-search-parent .ant-input-outlined {
  border-right: none !important;
  border-color: #d9d9d9 !important;
}

.custom-search-parent .custom-search {
  width: 370px !important;
  outline: none !important;
  border: none !important;
}

.custom-search .ant-input-clear-icon {
  scale: 1.5;
}

.custom-search-parent .ant-input-search .ant-input-search-button {
  height: 36px !important;
  border-left: none !important;
  border-radius: 6px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-color: #d9d9d9 !important;
  color: #8c8c8c !important;
}

.ant-select-show-arrow {
  width: 100% !important;
}

/* .ant-select-show-arrow span {
    color: #6E6B7B !important;
    font-weight: 700 !important;
} */
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #041e421a !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  height: 36px !important;
}

.ant-picker {
  width: 100% !important;
  border: 1px solid #d9d9d9;
  border-radius: 6px !important;
}

.ant-picker-input > input:placeholder-shown {
  border: none !important;
  line-height: 30px !important;
}

.ant-picker:hover,
.ant-picker-focused {
  border: 1px solid #d9d9d9;
}

.ant-picker-input > input {
  border: none !important;
  line-height: 30px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff !important;
  background-color: #56aaaa !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #56aaaa;
}

.ant-picker-header-view button:hover {
  color: #56aaaa !important;
}

.ant-picker-today-btn,
.ant-picker-today-btn:active,
.ant-picker-today-btn:hover {
  color: #56aaaa !important;
}

.date_picker input:focus {
  background: transparent !important;
}

.ant-input {
  border: 1px solid #dee2e6;
  font-size: 14px;
  line-height: 26px;
  background-color: transparent;
  border-radius: 6px;
}

.ant-input[disabled] {
  color: rgb(0, 0, 0, 0.75) !important;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff7875;
  box-shadow: none !important;
  border-right-width: 1px;
  outline: 0;
}

/* .ant-btn{
    background-color: #56aaaa!important;
    border: 1px solid #56aaaa!important;
    border-radius: 6px!important;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: .9px;
    padding: 5px 20px!important;
} */
/* 
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
    text-decoration: none;
    border-color: #d9d9d9 !important;
} */

.amazonicon .anticon svg {
  width: 22px !important;
  height: 22px !important;
}

.nestaed_tabel .ant-table-thead > tr > th {
  background-color: rgb(247, 244, 244) !important;
}

/* Antd Change Form Lable Hastrac To Right From Left */
.ant-form-item-required::before {
  position: absolute;
  right: 0;
}

.ant-form-item-required::after {
  content: '*' !important;
  display: inline-block !important;
  margin-right: 4px !important;
  color: #ff4d4f !important;
  font-size: 14px !important;

  line-height: 1 !important;
}
