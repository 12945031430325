.email-template-parent {
  min-height: 89vh !important;
}

.email-template-parent .ant-input {
  border-color: #dee2e6 !important;
  border-radius: 6px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.email-template-parent .ant-input:hover,
.email-template-parent .ant-input:focus-visible {
  border: none !important;
}

.email-template-parent .ant-input-search .ant-input-affix-wrapper {
  background: #f7f7f7 !important;
  border-color: #dee2e6 !important;
  border-right: none;
  box-shadow: none !important;
}

.email-template-parent .ant-input:focus-within {
  box-shadow: none !important;
}

.email-template-parent .ant-input-group-addon button {
  height: 36px !important;
  border-left: none;
  background: #f7f7f7 !important;
  border-radius: 6px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  color: #888888 !important;
  border-color: #d9d9d9 !important;
}

.email-template-parent .ant-table-tbody tr:nth-child(odd) {
  background-color: #fbfbfb;
}

.templateName {
  font-weight: bolder;
  color: #2d3542 !important;
  word-spacing: 1px;
}

.templateCategory {
  color: #2d3542 !important;
}

.templateStatus {
  color: #2d3542 !important;
  font-weight: 700;
}

.rotateIcon {
  transform: rotate(-45deg) !important;
}

.modalCloseIcon {
  scale: 1.3;
}

.email-template-parent label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: absolute;
  right: 0;
}
.emailTemplateForm label {
  color: #2d3542 !important;
}

.customBtn {
  font-size: 14px;
  line-height: 1.5714285714285714;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
  background: white;
  outline: none;
  border: none;
  color: #56aaaa;
}

.editorParent {
  position: relative !important;
}

.variableParent {
  position: absolute;
  top: 0;
  right: 0;
}

.variableParent button {
  border: 1px solid #56aaaa;
  color: #56aaaa;
}

.ant-modal-close-x svg:focus {
  outline: none !important;
}

.templatePreviewParent {
  width: 876px;
  height: 601px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f9fe;
}

.templatePreviewContent {
  background: white;
  width: 800px;
  height: 537px;
  overflow: auto;
}

.inviteeButton {
  height: 40px;
  background: white !important;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  color: #656b75 !important;
  padding: 6px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}

.filterLayout {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  place-content: start;
  /* gap: 24px; */
}

.headerSelect .ant-select-selector {
  left: 5px;
}

.no-hover-border .ant-input:hover {
  border: none !important;
}

.alignButtons {
  padding-top: 9px;
}

.emojiParent {
  position: relative;
}

.emojiDivChild {
  position: absolute;
  top: 0;
  right: -25px;
}

/* .collapseCheckboxes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
} */

.emojiParent {
  position: relative;
}

.emojiSelector {
  position: absolute;
  top: 41.5px;
  right: -920px;
}

.delivery-status-column {
  border-top: 1px solid #e8e8e8;
}

.templatePreviewContent {
  padding: 16px;
}

.templatePreviewContent p {
  margin: 0;
}

.notificationSelect .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 100% !important;
}

.editorParent span.ant-input-affix-wrapper,
.editorParent span.ant-input-affix-wrapper {
  z-index: 0 !important;
}
