.bankAccountCard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
}

.overviewCardLabel {
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.userStats p {
  scale: 0.8;
}

.customTab .ant-tabs {
  .ant-tabs-tab {
    transition: background-color 0.3s ease;
    /* border-radius: 6px !important; */
    /* padding: 6px !important; */
  }

  .ant-tabs-tab:not(.ant-tabs-tab-active) {
    /* border: 1px solid #44aaa9; */
    color: #44aaa9;
  }

  .ant-tabs-tab:hover:not(.ant-tabs-tab-active) {
    background: #e5f9fb;
  }

  .ant-tabs-tab-active {
    background-color: #44aaa9; /* Background color for active tab */
    color: white; /* Text color for active tab */
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important; /* Ensure text color stays white for active tab */
    /* padding: 3px; */
  }
}
.userPreview {
  display: grid;
  grid-template-columns: 0.125fr 1fr;
  gap: 15px;
  grid-template-rows: auto;
  place-items: center;
}

.userPreview .ant-descriptions-item-label span {
  font-size: 20px;
}

.userStats {
  border: 1px solid #e6e6e8;
}

.icon_1 {
  width: 50px;
  height: 50px;
  background: #fae3b1;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
}

.icon_2 {
  width: 50px;
  height: 50px;
  text-align: center;
  background: #ddf3e2;
  line-height: 50px;
  border-radius: 5px;
}

.userStats .icon_22 img {
  color: #56c36e;
  border-radius: 5px;
}

.icon_3 {
  width: 50px;
  height: 50px;
  text-align: center;
  background: #fbdadb;
  line-height: 50px;
  border-radius: 5px;
}

.icon_4 {
  width: 50px;
  height: 50px;
  text-align: center;
  background: #dff3fd;
  line-height: 50px;
  border-radius: 5px;
}
.icon_5 {
  width: 50px;
  height: 50px;
  background: #fae3b1;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
}

.icon_6 {
  width: 50px;
  height: 50px;
  text-align: center;
  background: #ddf3e2;
  line-height: 50px;
  border-radius: 5px;
}

.overviewLabel {
  font-size: 16px !important;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.emailChildPreview {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0;
  margin: 0;
}

.emailChildPreview p {
  width: 150px;
  text-align: start;
  padding: 0;
  margin: 0;
}

.userPreviewDetails .ant-typography {
  margin-bottom: 0;
  padding: 0;
}

.userPreviewDetails td {
  border: none;
}

.userStatsPreview {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  grid-template-rows: auto;
}

@media screen and (max-width: 600px) {
  .userStatsPreview {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .userPreview {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .userStatsPreview {
    grid-template-columns: repeat(2, 1fr);
  }
  .userPreview {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 992px) {
  .userStatsPreview {
    grid-template-columns: repeat(2, 1fr);
  }
}
