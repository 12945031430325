.status-selector {
  width: 230px !important;
  .ant-select-selector {
    height: 100% !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 100% !important;
  }

  .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent !important;
  }
}
