.dashboardStatsParent .ant-statistic-title {
  order: 2 !important;
  font-size: 16px;
  font-weight: bolder;
}

.dashboardStatsParent .ant-statistic-content {
  order: 1 !important;
  font-size: 26px;
}
