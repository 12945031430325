.rdp {
    --rdp-cell-size: 30px;
    --rdp-accent-color: #56AAAA;
    --rdp-background-color: #e7edff;
    /* Switch to dark colors for dark themes */
    --rdp-accent-color-dark: #56AAAA;
    --rdp-background-color-dark: #56AAAA;
    /* Outline border for focused elements */
    --rdp-outline: 2px solid var(--rdp-accent-color);
    /* Outline border for focused and selected elements */
    --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
  }

  .rdp-table {
    margin: 0;
    max-width: calc(var(--rdp-cell-size) * 7);
    border-collapse: collapse;
  }
  .rdp-caption_label{
    font-size: 15px;
  }

  .rdp td {
    border: 1px solid #0000001A;
    border-collapse: collapse;
    padding: 2px !important;
    color: #6E6B7B;
  }
  .rdp {
    margin: 0 !important;
  }